<nav class="safe-area fixed sm:bg-white bg-[#00263E] sm:pb-0 pb-2 shadow z-50">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 sm:mt-0 mt-2">
    <div class="relative flex justify-between">
      <div
        class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
      >
        <a href="/home/dashboard" class="flex flex-shrink-0 items-center">
          <!-- Image for small screens -->
          <img
            class="h-11 w-auto sm:hidden"
            src="assets/images/page-title.png"
            alt="Aliança"
          />

          <!-- Image for non-small screens -->
          <img
            class="hidden h-8 w-auto sm:block"
            src="assets/alianca-logo.svg"
            alt="Aliança"
          />
        </a>
        <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
          <a
            routerLink="/home/dashboard"
            class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:border-pink hover:text-pink transition duration-300"
            routerLinkActive="border-b-2 border-pink text-pink"
            >{{ "home" | transloco }}</a
          >
          <a
            routerLink="/home/classes"
            class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:border-pink hover:text-pink transition duration-300"
            routerLinkActive="border-b-2 border-pink text-pink"
            >{{ "classes" | transloco }}</a
          >
          <a
            routerLink="/home/documents"
            class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:border-pink hover:text-pink transition duration-300"
            routerLinkActive="border-b-2 border-pink text-pink"
            >{{ "documents" | transloco }}</a
          >
          <!--<a
            routerLink="/home/tickets"
            class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:border-pink hover:text-pink transition duration-300"
            routerLinkActive="border-b-2 border-pink text-pink"
            >{{ "support" | transloco }}</a
          >-->
        </div>
      </div>
      <div
        class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
      >
        <app-language-selector></app-language-selector>

        <!-- Profile dropdown -->
        <div class="relative ml-3">
          <div>
            <button
              (click)="toggleProfileMenu()"
              type="button"
              class="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gray-50 focus:ring-offset-2"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span class="absolute -inset-1.5"></span>
              <img
                class="h-8 w-8 rounded-full"
                src="{{ user?.profilePicture }}"
                alt=""
                *ngIf="!loading"
              />
              <div
                class="h-8 w-8 rounded-full bg-gray-200 animate-pulse"
                *ngIf="loading"
              ></div>
            </button>
          </div>
          <div
            *ngIf="isProfileDropdown"
            [@dropdownAnimation]
            class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-gray-500 ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <!-- Active: "bg-gray-100", Not Active: "" -->
            <a
              routerLink="/home/profile"
              (click)="closeToggleProfileMenu()"
              class="block px-4 py-2 text-sm text-gray-500 hover:bg-gray-100 transition duration-300 cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-0"
              >{{ "myAccount" | transloco }}</a
            >
            <a
              (click)="logout()"
              class="block px-4 py-2 text-sm text-gray-500 hover:bg-gray-100 transition duration-300 cursor-pointer"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
              >{{ "logout" | transloco }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
