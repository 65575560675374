<!-- Bottom Mobile menu, show/hide based on menu state. -->
<nav
  class="safe-area sm:hidden fixed inset-x-0 bottom-0 z-50 bg-white shadow-lg"
  [@menuAnimation]
>
  <div class="flex justify-between text-gray-500">
    <a
      routerLink="/home/dashboard"
      class="flex flex-col items-center justify-center w-1/4 hover:text-pink transition duration-300 text-center h-16"
      routerLinkActive="text-pink"
    >
      <img
        class="h-6 w-auto filter mt-2 mb-2 mx-auto"
        src="assets/images/home.svg"
        alt="Inicio"
      />
      <span class="text-xs font-medium mb-2">{{ "home" | transloco }}</span>
    </a>
    <a
      routerLink="/home/classes"
      class="flex flex-col items-center justify-center w-1/4 hover:text-pink transition duration-300 text-center h-16"
      routerLinkActive="text-pink"
    >
      <img
        class="h-6 w-auto filter mt-2 mb-2 mx-auto"
        src="assets/images/classes.svg"
        alt="Classes"
      />
      <span class="text-xs font-medium mb-2">{{ "classes" | transloco }}</span>
    </a>
    <a
      routerLink="/home/profile"
      class="flex flex-col items-center justify-center w-1/4 hover:text-pink transition duration-300 text-center h-16"
      routerLinkActive="text-pink"
    >
      <img
        class="h-6 w-auto filter mt-2 mb-2 mx-auto"
        src="assets/images/profile.svg"
        alt="Perfil"
      />
      <span class="text-xs font-medium mb-2">{{ "profile" | transloco }}</span>
    </a>
    <a
      routerLink="/home/documents"
      class="flex flex-col items-center justify-center w-1/4 hover:text-pink transition duration-300 text-center h-16"
      routerLinkActive="text-pink"
    >
      <img
        class="h-6 w-auto filter mt-2 mb-2 mx-auto grayscale"
        src="assets/images/clipboard-document.svg"
        alt="Suporte"
      />
      <span class="text-xs font-medium mb-2">{{
        "documents" | transloco
      }}</span>
    </a>
    <!--<a
      routerLink="/home/tickets"
      class="flex flex-col items-center justify-center w-1/4 hover:text-pink transition duration-300 text-center h-16"
      routerLinkActive="text-pink"
    >
      <img
        class="h-6 w-auto filter mt-2 mb-2 mx-auto"
        src="assets/images/support.svg"
        alt="Suporte"
      />
      <span class="text-xs font-medium mb-2">{{ "support" | transloco }}</span>
    </a>-->
  </div>
</nav>
