import { Component, ElementRef, HostListener, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { UserService } from '../../../services/user/user.service';
import { User } from 'src/app/services/user/user.types';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  animations: [
    trigger('dropdownAnimation', [
      state('void', style({
        transform: 'opacity-0 scale-95',
        opacity: 0
      })),
      state('*', style({
        transform: 'opacity-100 scale-100',
        opacity: 1
      })),
      transition('void => *', animate('200ms ease-out')),
      transition('* => void', animate('75ms ease-in'))
    ]),
    trigger('menuAnimation', [
      state('void', style({
        transform: 'translateX(-100%)',
        opacity: 1
      })),
      state('*', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('void => *', animate('200ms ease-out')),
      transition('* => void', animate('200ms ease-in'))
    ])
  ]
})
export class NavbarComponent implements OnInit, OnDestroy {
  isProfileDropdown = false;
  user: User;
  loading: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private elementRef: ElementRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
          this.loading = false;
        }
      },
        error => {
          console.log('Erro ao obter usuário', error);
          this.loading = false;
        });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  logout(): void {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  //close dropdownMenu with click outside
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (clickedInside) {
      this.isProfileDropdown = false;
    }
  }

  //open profileMenu with other click button
  toggleProfileMenu(): void {
    this.isProfileDropdown = !this.isProfileDropdown;
  }

  closeToggleProfileMenu(): void {
    this.isProfileDropdown = !this.isProfileDropdown;
  }

  //close profileMenu with other click outside
  @HostListener('document:click', ['$event.target'])
  onClickProfile(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isProfileDropdown = false;
    }
  }
}
