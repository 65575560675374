import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ClassroomClient } from 'video-api-client';
import { NotificationService } from '../notifications/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ClassroomGuard implements CanActivate {

  constructor(
    private router: Router,
    private classroomClient: ClassroomClient,
    private notificationService: NotificationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const param1 = route.params['classId'];
    return this.classroomClient.currentUserEligible(param1).pipe(
      map((result) => result),
      tap((isAllowed) => {
        if (!isAllowed) {
          this.notificationService.error("Erro", "Não foi possível achar classe.");
          setTimeout(() => this.router.navigate(['/home/dashboard']), 0);
        }
      }),
      catchError((err) => {
        this.notificationService.error("Erro", "Não foi possível achar classe.");
        setTimeout(() => this.router.navigate(['/home/dashboard']), 0);
        return of(false);
      })
    );
  }
}
