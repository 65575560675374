import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanMatch, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate
{
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._authService.check().pipe(
            switchMap(authenticated => {
                if (!authenticated) {
                    // Construct the redirect URL from the state URL
                    const redirectURL = state.url;
                    const urlTree = this._router.parseUrl(`login?redirectURL=${encodeURIComponent(redirectURL)}`);

                    return of(urlTree);
                }

                // Allow the access
                return of(true);
            })
        );
    }
}