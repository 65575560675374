import { Injectable } from '@angular/core';
import { catchError, forkJoin, from, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from './auth.utils';
import { UserService } from '../user/user.service';
import { AuthClient, AuthResponse, LoginCommand, ResetPasswordCommand, SendResetPasswordTokenCommand, ValidateResetTokenCommand } from '../../../../api-client';
import {
  AuthClient as AuthClientVideo,
  AuthResponse as AuthResponseVideo,
  LoginCommand as LoginCommandVideo
} from '../../../../video-api-client';
import { FcmService } from '../fcm/fcm.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _authenticated: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private readonly authClient: AuthClient,
    private readonly authClientVideo: AuthClientVideo,
    private readonly _userService: UserService,
    private readonly _fcmService: FcmService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  // set videoAccessToken(token: string) {
  //   localStorage.setItem('videoAccessToken', token);
  // }

  get accessToken(): string {
    const token = localStorage.getItem('accessToken');
    return token !== null ? token : '';
  }

  // get videoAccessToken(): string {
  //   const token = localStorage.getItem('videoAccessToken');
  //   return token !== null ? token : '';
  // }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this.authClient.sendResetPasswordToken(<SendResetPasswordTokenCommand>{
      email: email
    });
  }

  resetPassword(email: string, password: string, token: string): Observable<any> {
    return this.authClient.resetPassword(<ResetPasswordCommand>{
      email: email, password: password, token: token
    });
  }

  validateToken(email: string, token: string): Observable<any> {
    return this.authClient.validateResetToken(<ValidateResetTokenCommand>{
      email: email,
      token: token
    })
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error if the user is already logged in
    if (this._authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    // Convert the Promise returned by getToken to an Observable
    return from(this._fcmService.getToken()).pipe(
      switchMap(token => {
        var loginCommand = <LoginCommand>{
          login: credentials.email,
          password: credentials.password,
          mobileDeviceToken: token
        };

        // const loginCommandVideo = <LoginCommandVideo>{
        //   login: credentials.email,
        //   password: credentials.password
        // };

        // return forkJoin([
        //   this.authClient.login(loginCommand),
        //   this.authClientVideo.login(loginCommandVideo)
        // ]);

        return this.authClient.login(loginCommand);
      }),
      //switchMap(([response, responseVideo]: [AuthResponse, AuthResponseVideo]) => {
      switchMap((response: AuthResponse) => {
        // Store the access token and set authenticated flag
        this.accessToken = response.token;
        //this.videoAccessToken = responseVideo.token;
        this._authenticated = true;

        // Store the user details in the user service
        this._userService.user = response.user;

        // Return the response encapsulated in an Observable
        return of(response);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    //localStorage.removeItem('videoAccessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    return of(true);
  }
}
