import { Component, ElementRef, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent {
  languages = [
    { code: 'pt', name: 'Português', flag: '../../../../assets/flags/br.svg' },
    { code: 'es', name: 'Español', flag: '../../../../assets/flags/es.svg' },
    { code: 'us', name: 'English', flag: '../../../../assets/flags/us.svg' }
  ];

  selectedLanguage: { code: string, name: string, flag: string };

  constructor(
    private translocoService: TranslocoService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    const storedLang = localStorage.getItem('lang');
    console.log('Stored Lang:', storedLang);
    if (storedLang) {
      this.selectedLanguage = this.languages.find(language => language.code === storedLang);
      console.log('Selected Language:', this.selectedLanguage);
    } else {
      const activeLang = this.translocoService.getActiveLang();
      this.selectedLanguage = this.languages.find(language => language.code === activeLang);
    }
    this.setLanguage(this.selectedLanguage);
  }

  setLanguage(language: { code: string, name: string, flag: string }): void {
    this.translocoService.setActiveLang(language.code);
    this.selectedLanguage = language;
    localStorage.setItem('lang', language.code);
    this.translocoService.load(language.code);
  }
}
