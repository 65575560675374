<div>
  <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="flex items-center">
    <img [src]="selectedLanguage.flag" alt="Flag" />
  </button>

  <mat-menu #languageMenu="matMenu" xPosition="before">
    <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">
      <div class="flex items-center gap-x-2">
        <img [src]="language.flag" class="w-5 h-5" alt="Flag" />
        <span class="text-gray-700 font-normal text-sm">{{ language.name }}</span>
      </div>
    </button>
  </mat-menu>
</div>